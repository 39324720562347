.buttonBackToHome{
    background-color:#5FBEE6 ;
    width: 200px;
    height: 699px;
    border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  position: absolute;
  border: none;
  left: 0; /* Alinha na lateral esquerda */
  top: 50%; 
  transform: translateY(-50%); /* Ajusta a posição vertical */

}

.buttonBackToHome img{
    width: 150px;
    height: 150px;
}

.buttonBackToHome p{
 font-size: 40px;
}