.bussinessWrapper{
    display: flex;
    justify-content: space-around;
    height: 50%;
}

.containerBussinessText{
    width: 720px;
    text-align: start;
}

.containerBussinessImage{
    width: 512px;
    height: 740px;
    display: flex;
    justify-content: center;
}
.containerBussinessImage button{
background-color: transparent;
border: none;
}
.rHandImage{
    width: 250px;
    height: 270px;
    align-self: flex-end;
}

.businessImg{
    width: 512px;
    height: 682px;
}

.wrapperRHandImage{
    display: flex;
    justify-content: flex-end;
    height: 100%;
}

@keyframes shake {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-55px);
    }
    70% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .shakingHand {
    animation: shake 2s infinite; /* Adicione a classe shaking-hand ao elemento da imagem da mãozinha */
  }

  .overlay{
    position: absolute;
    top: 0;
  }

  .popup{
  width: 1800px;
  height: 3200px;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  z-index: 1; 

  }

  .closeButton {
  font-size: 50px;
  width: 200px;
  background: rgba(255, 255, 255, 48%);
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 13.5px );
  height: 200px;
  border-radius: 120px;
  }
  
  .wrapperCloseButton{
    position: fixed;
    top: 30px;
    right: 129px;
    z-index: 3;
  }