.App {
  text-align: center;
}
body{
  background: rgba(58, 88, 111, 0.319);
  backdrop-filter: blur( 1.5px );
  -webkit-backdrop-filter: blur( 13.5px );
  
}
@font-face {
  font-family: 'Figtree';
  src: url('./fonts/Figtree-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'FigtreeBold';
  src: url('./fonts/Figtree-Bold.ttf') format('truetype');
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
span{
  font-family: 'Figtree';
  font-size: 120px;
  color: white;


}
p{
  color: white;
  font-family: 'FigtreeBold';
  font-size: 70px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapperWelcomeText{
  height: 623px;
}
.wrapper{
  display: flex;
  height: 3418px;
  flex-direction: column;
  justify-content: space-around;
}

@keyframes shake {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-55px);
  }
  70% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

.shaking-hand {
  animation: shake 2s infinite; /* Adicione a classe shaking-hand ao elemento da imagem da mãozinha */
}